@import "aos/dist/aos.css";
@import "@wazoe/elements/styles/elements";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-text;
  overflow-x: hidden;
}

.prose > p {
  margin: 0;
}

.prose > ol {
  margin: 0;
}

.prose > ul {
  margin: 0;
}
@layer components {  body {    @apply font-normal leading-[1.6] text-[15.2px] text-black bg-[#F6F6F6];  }    .container{        @apply mx-auto xl:max-w-[1200px]    }}.hero-img-text {  text-shadow: 2px 2px 5px rgba(0,0,0,.11);}@layer base {  .form-input {    @apply rounded-none px-2 py-1.5 !important;  }  .form-button {    @apply inline-block hover:bg-[#346993] rounded-sm text-[16px] text-white py-3 px-7 transition-all !important;  }}.usp-1, .usp-2, .usp-3 {	position: absolute;  display: block;	animation-duration: 22s;	animation-timing-function: ease-in-out;	animation-iteration-count: infinite;  width: 100%;}.usp-1{animation-name: anim-1;}.usp-2{animation-name: anim-2;}.usp-3{animation-name: anim-3;}@keyframes anim-1 {	0%, 8.3% { left: -100%; opacity: 0; }  8.3%,25% { left: 0; opacity: 1; }  33.33%, 100% { left: 50%; opacity: 0; }}@keyframes anim-2 {	0%, 33.33% { left: -100%; opacity: 0; }  41.63%, 58.29% { left: 0; opacity: 1; }  66.66%, 100% { left: 50%; opacity: 0; }}@keyframes anim-3 {	0%, 66.66% { left: -100%; opacity: 0; }  74.96%, 91.62% { left: 0; opacity: 1; }  100% { left: 50%; opacity: 0; }}.voordelen-text h2 {  margin:0 !important;}